@mixin btn($color: gray, $icon: null) {
  @if $icon == img {
    padding: 10px 20px 10px 15px;
    display: flex;
    align-items: center;
  } @else {
    padding: 10px 20px;
    display: inline-block;
  }

  @if $color == blue {
    background-color: $btnBlue;
    border-color: $btnBlue;
    color: #fff !important;
  } @else if $color == green {
    background-color: $btnGreen;
    border-color: $btnGreen;
    color: #fff;
  } @else if $color == red {
    background-color: $btnRed;
    border-color: $btnRed;
    color: #fff;
  } @else if $color == white {
    background-color: #fff;
    border-color: #fff;
    color: #016f9d;
    font-family: $bold;
  } @else if $color == gray {
    background-color: #E0E0E0;
    border-color: #E0E0E0;
    color: #323232;
  } @else if $color == disabledColor {
    background-color: $disabledColor;
    border-color: $disabledColor;
    color: #fff;
    font-family: $medium;
  }
  @else {
    background-color: transparent;
    border-color: $bdcolorGray;
    color: $defTextColor;
    font-family: $medium;
  }

  border-width: 1px;
  border-style: solid;
  min-width: 150px;
  text-decoration: none;
  text-align: center;
  border-radius:4px;

  @media (max-width: 767px) {
    width: auto;
  }
}

/*
**  Для кастомизации плейсхолдера.
*/

@mixin optional-at-root($sel) {
  @at-root #{if(not &, $sel, selector-append(&, $sel))} {
    @content;
  }
}

@mixin placeholder {
  @include optional-at-root('::-webkit-input-placeholder') {
    @content;
  }

  @include optional-at-root(':-moz-placeholder') {
    @content;
  }

  @include optional-at-root('::-moz-placeholder') {
    @content;
  }

  @include optional-at-root(':-ms-input-placeholder') {
    @content;
  }
}
