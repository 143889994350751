$defTextColor: #4f4f4f;

$btnBlue: #3b92cf;
$btnBlueHover: #52a0d6;
$btnRed: #e04444;
$btnGreen: #4cba37;
$btnWhite: #fff;

$disabledColor: rgb(189, 189, 189);
$bdcolorGray: #e6e6e6;

$fallbackFont: Arial, sans-serif;
$bold: "Roboto-Bold", $fallbackFont;
$regular: "Roboto-Regular", $fallbackFont;
$medium: "Roboto-Medium", $fallbackFont;
$light: "Roboto-Light", $fallbackFont;