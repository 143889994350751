@import "~reset-css";
@import "_vars";
@import "_mixins";
@import "_fonts";

@import "_nojs";
@import "_mobile";

* {
  outline: none;
  line-height: 20px
}

html, body {
  overflow-x: hidden
}

body {
  font: 14px $regular;
  color: $defTextColor;
}

a:hover, a:active, a:focus {
  text-decoration: none
}

strong, b {
  font-weight: "Roboto-Bold", Arial, sans-serif
}

button {
  border: none;
}

.btn-white {
  @include btn(white);
}

.btn-blue {
  @include btn(blue);
}

.basic-container {
  height: 100vh;

  > .row {
    display: flex;
  }

  main, aside,
  .auth-container,
  .guest-container {
    height: 100vh;
  }

  .auth-container,
  .guest-container {
    @media (max-width: 812px) and (orientation: landscape) {
      height: 100%;
      padding: 20px 0;
    }
  }
}

.guest-container,
.auth-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.guest-container {
  background: url(../img/bg.jpg) no-repeat 0 0;
  color: #fff;
  margin-right: 520px;
  position: relative;
  background-size: cover;
  opacity: 1;
  transition: all .2s linear;

  &:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0, .5);
  }

  &.hidden {
    display: none;

    + .auth-container {
      display: flex;
    }
  }

  @media (max-width: 1600px) {
    margin-right: 390px;
  }

  @media (max-width: 1024px) {
    margin-right: 0;
  }

  @media (max-width: 568px) and (orientation: landscape) {
    height: auto !important;
  }
}

.guest-container__wrap {
  width: 800px;
  z-index: 1;

  + button {
    position: absolute;
    top: 10px;
    right: 15px;
    font-family: $bold;
    display: none;
    z-index: 1;

    background-color: transparent;

    @media (max-width: 1024px) {
      display: flex;
      align-items: center;

      svg.icon {
        display: inline-block;
        margin-left: 5px;
      }
    }
  }

  @media (max-width: 1280px) {
    width: 600px;
  }

  @media (max-width: 1024px) {
    width: 290px;
    max-width: 100%;
    text-align: center;
  }
}

.guest-container__title {
  margin-bottom: 13px;

  @media (max-width: 1024px) {
    margin-bottom: 23px;
  }

  h1 {
    font: 36px $medium;

    @media (max-width: 1024px) {
      line-height: 1;
      font-size: 30px;
    }
  }
}

.guest-container__desc {
  font: 20px $light;

  p {
    line-height: 30px;

    @media (max-width: 1024px) {
      line-height: 24px;
      font-size: 16px;
    }

    a {
      text-decoration: underline;
    }
  }

  a {
    color: #fff;
  }

  > a {
    float: left;
    font-family: $bold;

    small {
      font: 12px $light;
    }

    @media (max-width: 1024px) {
      float: none;
    }
  }

  a + span {
    margin-left: 5px;
    font-size: 16px;
    line-height: 30px;
  }

  div {
    margin: 20px 0;
    display: flex;
    align-items: center;

    @media (max-width: 1024px) {
      display: block;
    }

    small {
      font-family: $light;
      font-size: 12px;
      margin-left: 15px;

      @media (max-width: 1024px) {
        display: block;
        margin: 10px 0 0 0;
      }
    }

    a {
      font: 16px $regular !important;
      padding-top: 12px;
      padding-bottom: 12px;

      @media (max-width: 568px) {
        width: 100% !important;
      }
    }
  }
}

.guest-container__dlinks {
  //display: none;

  a {
    display: inline-block;

    &:last-child {
      margin-left: 20px;
    }
  }

  img {
    height: 40px;
  }

}

.auth-container {
  background-color: $btnBlue;
  color: #fff;
  position: relative;
  padding-bottom: 24px;
  position: absolute;
  right: 0;
  top: 0;
  width: 520px;
  transition: all .2s linear;

  > button {
    position: absolute;
    top: 10px;
    left: 15px;
    font-family: $bold;
    display: none;

    background-color: transparent;

    @media (max-width: 1024px) {
      display: flex;
      align-items: center;

      svg.icon {
        display: inline-block;
        margin-right: 5px;
      }
    }
  }

  @media (max-width: 1600px) {
    width: 390px;
  }

  @media (max-width: 1024px) {
    width: 100%;
    display: none;
  }
}

.auth-container__body {
  width: 360px;
  height: 270px;

  input {
    font: 18px $light;
    width: 100%;
    margin-bottom: 27px;
    padding-bottom: 5px;
    background-color: $btnBlue !important;
    border: none;
    border-bottom: 1px solid #c5dff1;
    color: #fff;
    overflow-x: hidden;

     + label {
      display: block;
      position: relative;

      @media (max-width: 1024px) {
        text-align: center;
      }

      > span {
        color: #c5dff1;
        position: absolute;
        width: 100%;
        left: 0;
        top: -50px;
        z-index: 100;
        transition: all 0.2s ease;
      }
    }

    @include placeholder {
      opacity: 1;
      transition: opacity 0.3s ease-in-out;
    }

    &:focus {
      @include placeholder {
        opacity: 0;
      }
    }

    &:focus, &:valid {

      + label > span {
        top: -75px;
        font-size: 12px;
      }
    }

    @media (max-width: 1024px) {
      text-align: center;
    }
  }

  button {
    &:nth-of-type(1) {
      margin-right: 27px;

      @media (max-width: 1600px) {
        margin-bottom: 10px;
      }
    }

    &:nth-of-type(2) {
      @include btn;
      background-color: transparent;
      padding-right: 0;
      padding-left: 0;
      color: #fff;
      width: auto;
      border: none;
      font-family: $regular;
    }

    &:nth-of-type(2):hover {
      text-decoration: underline;
    }

    font-size: 16px;

    @media (max-width: 1600px) {
      width: 100% !important;
    }
  }

  p {
    position: relative;
    height: 0;
    margin-top: 20px;
    text-align: center;
    opacity: 0;
    transition: all .2s linear;
    @media (max-width: 1600px) {
      margin-top: 4px;
      margin-bottom: 10px;
    }
    &.visible {
      opacity: 1;
      height: auto;
    }
    &.visible_error {
      opacity: 1;
      color: white;
      background-color: #a41212;
      border: 1px;
      border-radius: 5px;
      text-align: center;
      font-size: 16px;
      padding-top: 3px;
      height: 30px;
    }
    a {
      font-family: $medium;
      color: #fff;
    }
  }

  @media (max-width: 1600px) {
    width: 290px;
    margin-top: 30px;
  }
}

.auth-container__footer {
  margin-top: 24px;
  position: absolute;
  bottom: 20px;
  left: 0;
  width: 100%;

  li {
    display: inline-block;
    margin-right: 15px;

    &:last-child {
      margin-right: 0;
    }
  }

  a {
    font-family: $regular;
    color: #fff;

    &:hover {
      text-decoration: underline;
    }
  }
}
#cookie_notification{
  display: none;
  justify-content: space-between;
  align-items: flex-end;
  position: fixed;
  bottom: 15px;
  left: 50%;
  width: 870px;
  max-width: 90%;
  transform: translateX(-50%);
  padding: 10px 15px 10px 25px;
  background-color: #fff;
  border-radius: 4px;
  color: #1E1E1E;
  box-shadow: 2px 3px 10px rgba(0, 0, 0, 0.4);
}

#cookie_notification p{
  margin: 0;
  font-size: 0.7rem;
  text-align: left;
  color: #3b92cf;
}
#cookie_notification a{
  color: #005CB9;
  text-decoration: underline;
}


@media (min-width: 576px){
  #cookie_notification.show{
    display: flex;
  }
  .cookie_accept{
    margin: 0 0 0 25px;
    color: #005CB9;
    border-radius: 4px;
    font-family: "Roboto-Bold", Arial, sans-serif;
    border-radius: 4px;
    padding: 10px;
  }
}

@media (max-width: 575px){
  #cookie_notification.show{
    display: block;
    text-align: left;
  }
  .cookie_accept{
    margin: 10px 0 0 0;
    color: #2C86F1;
    border-radius: 4px;
    padding: 10px;

  }
}
