.screen-coming-soon {
  background-color: #3e4347;
  overflow: auto;
  color: #fff;
  position: fixed;
  z-index: 9999;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100%;
  display: none;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
  justify-content: center;
  padding: 30px;

  // Модификатор активирует показ блока через js.
  &_active {
    display: flex;
  }

  &__inner {
    display: flex;
    flex-direction: column;
    justify-content: center;

    a {
      color: #fff;
    }

    img {
      width: 150px;
      margin: 0 auto 36px;
    }
  }

  &__links {
    display: flex;
    flex-direction: row;
    align-items: center;

    img {
      width: 150px;
      height: 45px;
    }

    a:first-child {
      margin-right: 15px;
    }
  }

  &__title {
    font: bold 16px $bold;
    line-height: 20px;
    width: 100%;
    margin-bottom: 12px;
    text-align: center;
  }

  &__desc {
    font: 14px $regular;
    line-height: 18px;
    max-width: 300px;
    text-align: center;
    margin-bottom: 20px;
  }
}
.icon-arrow-left {
  font-size: 18px;
  right: -60px !important;
}
.icon-arrow-right {
    font-size: 18px;
}
@media screen and (min-width : 800px) {
  .icon-arrow-left {
    font-size: 18px;
    margin: 200px !important;
  }
  .icon-arrow-right {
    font-size: 18px;
    margin: 200px !important;
  }
}
